import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Logos.module.scss'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const b = bem.logos(css)

const Logos = ({ items }) => {
	const data = useStaticQuery(graphql`
		query ImagesTechnologiesQuery {
			allFile(filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "technologies" } }) {
				edges {
					node {
						name
						childImageSharp {
							fixed {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			}
		}
	`)

	let images = items.map(item => ({
		node: data.allFile.edges.find(({ node }) => node.name === item.image).node,
		item,
	}))

	const handleOpen = url => {
		if (!url) return
		window.open(url, '_blank')
	}

	return (
		<div className={b()}>
			<div className={b('items')}>
				{images.map(({ item: { title, url }, node: { childImageSharp: { fixed } } }, index) => (
					<div
						role="button"
						tabIndex={0}
						key={index}
						className={b('item', { 'is-link': !!url })}
						onClick={() => handleOpen(url)}
						onKeyDown={() => {}}
						title={title}
					>
						<Img fixed={fixed} width={fixed.width} height={fixed.height} alt={title} />
					</div>
				))}
			</div>
		</div>
	)
}

Logos.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			image: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			url: PropTypes.string,
		}).isRequired
	).isRequired,
}

export default Logos

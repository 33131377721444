import React from 'react'
import Logos from 'components/Logos'

// prettier-ignore
const Technologies = () => (
	<Logos
		items={[
			{ image: 'python', title: 'Python', url: 'https://www.python.org' },
			{ image: 'nodejs', title: 'Node js', url: 'https://nodejs.org' },
			{ image: 'aws', title: 'Amazon Web Services (AWS)', url: 'https://aws.amazon.com/ru' },
			{ image: 'docker', title: 'Docker', url: 'https://www.docker.com' },
			{ image: 'mysql', title: 'MySQL', url: 'https://www.mysql.com' },
			{ image: 'mongodb', title: 'MongoDB', url: 'https://www.mongodb.com' },
			{ image: 'bitrix', title: 'Bitrix 24', url: 'https://www.bitrix24.ru' },
			{ image: 'wordpress', title: 'WordPress', url: 'https://ru.wordpress.org' },
			{ image: 'opencart', title: 'OpenCart', url: 'https://www.opencart.com' },
			{ image: 'magento', title: 'Magento', url: 'https://magento.com' },
			{ image: 'shopify', title: 'Shopify', url: 'https://www.shopify.com' },
			{ image: 'simpla', title: 'Simpla CMS', url: 'https://simplacms.ru' },
			{ image: 'ghost', title: 'Ghost js', url: 'https://ghost.org' },
			// { image: 'figma', title: 'Figma', url: 'https://www.figma.com' },
			// { image: 'illustrator', title: 'Adobe Illustrator', url: 'https://www.adobe.com/ru/products/illustrator.html' },
			// { image: 'photoshop', title: 'Adobe Photoshop', url: 'https://www.adobe.com/ru/products/photoshop.html' },
			// { image: 'react', title: 'React js', url: 'https://reactjs.org' },
			// { image: 'graphql', title: 'GraphQL', url: 'https://graphql.org' },
			// { image: 'apollo', title: 'Apollo GraphQL', url: 'https://www.apollographql.com' },
			// { image: 'pwa', title: 'Progressive Web Applications', url: 'https://developers.google.com/web/progressive-web-apps' },
			// { image: 'html', title: 'HTML5 CSS3 JavaScript PHP7' },
			// { image: 'ws', title: 'WebSocket', url: 'https://developer.mozilla.org/ru/docs/Web/API/WebSocket' },
			// { image: 'gatsby', title: 'Gatsby js', url: 'https://www.gatsbyjs.org' },
		]}
	/>
)

export default Technologies

import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Stepper.module.scss'
import Img from 'gatsby-image'
import useMedia from 'hooks/useMedia'

const b = bem.stepper(css)

const Stepper = ({ data, images, titleTag: TitleTag }) => {
	const controlHeight = 73
	const controlsRef = useRef(null)
	const [maxHeight, setMaxHeight] = useState(0)
	const [current, setCurrent] = useState(0)
	const [image, setImage] = useState(null)
	const [imageAnimation, setImageAnimation] = useState(true)

	const breakpoint = useMedia(
		['(min-width: 1200px)', '(min-width: 992px)', '(min-width: 768px)', '(min-width: 576px)', '(min-width: 0px)'],
		['xl', 'lg', 'md', 'sm', 'xs']
	)

	// Вычисление высоты кнопок и сохранение в состоянии
	useLayoutEffect(() => {
		setMaxHeight(controlsRef.current.offsetHeight)
	}, [breakpoint])

	/* eslint-disable */
	// Поиск текущего изображения
	useEffect(() => {
		const searched = images.allFile.edges.find(({ node }) => node.name === data[current].image)
		searched && setImage(searched.node.childImageSharp.fluid)
	}, [current])
	/* eslint-enable */

	// Обновляем состояние анимации изображения для применения css-эффектов появления
	useEffect(() => {
		setImageAnimation(true)
		setTimeout(() => setImageAnimation(false))
	}, [current])

	// Alt-атрибут изображения
	const alt = data[current].alt

	return (
		<div className={b()}>
			<div className={b('preview')}>
				{image && <Img className={b('image', { 'is-animation': imageAnimation })} fluid={image} alt={alt} />}
			</div>
			<ul className={b('controls')} ref={controlsRef}>
				{data.map((v, index) => {
					const active = current === index
					return (
						<li key={`control-${index}`} className={b('control', { active })}>
							<button onClick={() => setCurrent(index)} className={b('button', { active })}>
								<span className={b('button-text')}>{index + 1}</span>
							</button>
						</li>
					)
				})}
			</ul>
			<div className={b('info')}>
				<div className={b('info-inner')} style={{ maxHeight }}>
					<div className={b('cloud')} style={{ marginTop: controlHeight * current }}>
						{data.map(({ title, text }, index) => {
							const active = current === index
							return (
								<div key={`part-${index}`} className={b('part', { active })}>
									{title && <TitleTag className={b('title')} dangerouslySetInnerHTML={{ __html: title }} />}
									<div className={b('text')}>{text}</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

Stepper.defaultProps = {
	titleTag: 'h3',
}

Stepper.propTypes = {
	titleTag: PropTypes.string,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			text: PropTypes.object.isRequired,
			image: PropTypes.string.isRequired,
		})
	),
	images: PropTypes.shape({
		allFile: PropTypes.shape({
			edges: PropTypes.arrayOf(
				PropTypes.shape({
					node: PropTypes.shape({
						name: PropTypes.string.isRequired,
						childImageSharp: PropTypes.shape({
							fluid: PropTypes.object.isRequired,
						}).isRequired,
					}).isRequired,
				})
			).isRequired,
		}).isRequired,
	}).isRequired,
}

export default Stepper

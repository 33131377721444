import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import Banner from 'components/Banner'
import Features from 'components/Features'
import Contacts from 'components/Contacts'
import FeedbackButton from 'components/FeedbackButton'
import Technologies from 'components/Technologies'
import FAQ from 'components/FAQ'
import Examples from 'components/Examples'
import bem from 'bem'
import css from './Home.module.scss'
import Stepper from 'components/Stepper'
import Triangle from 'components/Triangle'
import Img from 'gatsby-image'

const b = bem.sceneHome(css)

const Home = ({ images }) => {
	const pictures = {}
	images.allFile.edges.forEach(({ node }) => {
		pictures[node.name] = node.childImageSharp.fluid
	})
	return (
		<Layout className={b()}>
			<SEO
				title="Заказать парсинг сайтов — «Парсик»"
				description="Заказать парсинг сайтов, товаров. Получение и обновление данных в Excel формате. Разработка ТЗ. Поддержка 24/7"
				keywords="парсинг, заказ, база, excel, ексель, телефоны, email, поставщики, компании, товар"
			/>
			<Banner
				title="Парсинг сайтов"
				description="Структурированные данные для вашего сайта"
				image={{ name: 'home', width: 506, height: 392 }}
			>
				<FeedbackButton theme="light" size="lg">
					Заказать парсинг
				</FeedbackButton>
			</Banner>
			<Sector>
				<Grid fluid className="mb-xl">
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<h2 className="mb">Всегда актуальные товары и цены</h2>
							<p className="fs-h4">
								Получайте данные в удобном виде и используйте их на своем сайте. Сконцентрируйтесь на бизнесе, а сбор
								данных поручите нам
							</p>
						</Col>
					</Row>
				</Grid>
				<div className="mb-xl">
					<Features
						columns={3}
						isSector={false}
						data={[
							{
								url: '/',
								icon: 'loupWindow',
								title: 'Парсинг товаров',
								description: 'Скачаем и аккуратно предоставим данные в удобном Excel формате',
							},
							{
								url: '/',
								icon: 'documents',
								title: 'Базы компаний',
								description: 'Предоставим базы любых компаний с email-адресами и телефонами',
							},
							{
								url: '/',
								icon: 'update',
								title: 'Автоматическое обновление',
								description: 'Подключим регулярное обновление товаров на вашем сайте',
							},
						]}
					/>
				</div>
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Узнать стоимость
					</FeedbackButton>
				</div>
			</Sector>
			<Triangle color="white" top right>
				<Triangle color="white" bottom right>
					<Sector color="blue" title="Парсим сайты любой сложности!" isGradient isTriangles>
						<div className="fs-h4">
							<p>
								Мы&nbsp;занимаемся сбором, структурированием и&nbsp;форматированием информации, мониторингом
								сайтов-доноров и&nbsp;автоматизацией ручной работы.
							</p>
							<p>
								Если вам необходимо спарсить данные с&nbsp;одного или нескольких источников, предоставьте эту задачу
								профильным программистам. Закажите парсинг у&nbsp;нас и&nbsp;вы&nbsp;получите качественный сервис
								за&nbsp;относительно небольшие деньги.
							</p>
						</div>
					</Sector>
				</Triangle>
			</Triangle>
			<Sector title="Как это работает" titleCentered>
				<div className={b('how-image')}>
					<Img fluid={pictures['how-is-it']} alt="Схема работы парсинга" />
				</div>
			</Sector>
			<Examples />
			<Sector title="Этапы работы" titleCentered>
				<Grid fluid className={b('stepper-description')}>
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<p className="fs-h4 text-center">
								Залог успешной и плодотворной работы - понимать и придерживаться каждого из этапов
							</p>
						</Col>
					</Row>
				</Grid>
				<Stepper
					data={[
						{
							title: 'Заказ парсинга',
							text: (
								<ul>
									<li>Получение заявки с подробным описанием требований и пожеланий</li>
									<li>
										Анализ вашего сайта, сайтов-доноров и формирование идеи по получению, структуризации и выгрузке
										данных
									</li>
									<li>Обсуждение деталей, расчет стоимости заказа и сроков выполнения</li>
								</ul>
							),
							image: 'home-1',
							alt: 'Заказ парсинга. Получение заявки',
						},
						{
							title: 'Составление технического задания',
							text: (
								<ul>
									<li>
										Тесная работа с клиентом для детального описания каждого свойства, например: формат данных, частные
										случаи, сортировка, работа с дубликатами и пр.
									</li>
									<li>Фиксация сведений и утверждение плана работы</li>
									<li>Внесение аванса клиентом</li>
								</ul>
							),
							image: 'home-2',
							alt: 'Составление технического задания',
						},
						{
							title: 'Оказание услуги',
							text: (
								<ul>
									<li>Написание одного или нескольких парсеров сайтов-доноров</li>
									<li>Тестирование, настройка круглосуточной работы и мониторинга парсеров</li>
									<li>Выгрузка данных в нужном формате</li>
								</ul>
							),
							image: 'home-3',
							alt: 'Оказание услуги. Написание парсеров',
						},
						{
							title: 'Завершение работы',
							text: (
								<ul>
									<li>Составление и отправка отчета по оказанным услугам</li>
									<li>Окончательное утверждение работы и консультация по использованию готовых данных</li>
									<li>Оплата оставшейся части довольным клиентом</li>
									<li>Дальнейшая поддержка и обслуживание парсеров</li>
								</ul>
							),
							image: 'home-4',
							alt: 'Завершение работы. Составление и отправка отчета по оказанным услугам',
						},
					]}
				/>
			</Sector>
			<Triangle color="white" top right>
				<Triangle color="white" bottom right>
					<Sector color="gray" title="Наши преимущества" titleCentered isTriangles>
						<div className="mb-xl">
							<Features
								isSector={false}
								columns={3}
								data={[
									{
										icon: 'fastClock',
										title: 'Быстро работаем',
										description: 'Наш набор технических инструментов позволяет создать парсер в&nbsp;кратчайшие сроки',
									},
									{
										icon: 'team',
										title: 'Опытная команда',
										description:
											'У&nbsp;нас в&nbsp;штате 3&nbsp;опытных программиста уровней Middle и&nbsp;Senior со&nbsp;средним стажем 8&nbsp;лет',
									},
									{
										icon: 'tech',
										title: 'Современные технологии',
										description: 'Используем мощный сервер, многопоточность и&nbsp;новейшие технические решения',
									},
									{
										icon: 'servers',
										title: 'Сложный парсинг',
										description:
											'Применяем разные методы обхода защиты: от&nbsp;подбора капчи, до&nbsp;полноценной симуляции пользовательской активности',
									},
									{
										icon: 'webDev',
										title: 'Надежность',
										description:
											'Мы&nbsp;быстро вносим изменения в&nbsp;код парсера, если на&nbsp;сайте-доноре изменилась разметка или сработала защита',
									},
									{
										icon: 'xml',
										title: 'Индивидуальный подход',
										description: 'Подготовим для вас данные в&nbsp;том формате, который вам нужен',
									},
								]}
							/>
						</div>
						<div className="text-center">
							<FeedbackButton theme="primary" size="lg">
								Узнать стоимость
							</FeedbackButton>
						</div>
					</Sector>
				</Triangle>
			</Triangle>
			<Sector title="Технологии и CMS, с которыми мы работаем" titleCentered>
				<Technologies />
			</Sector>
			<Triangle color="white" top left>
				<Triangle color="white" bottom left>
					<Sector title="Вопросы и ответы" color="gray" isTriangles>
						<div className="mb-lg">
							<FAQ
								currentIndexes={[0]}
								isNumbers={true}
								items={[
									{
										title: 'Что такое парсинг сайта?',
										description: (
											<>
												<p>
													<strong>Парсинг</strong>&nbsp;&mdash; это программное сканирование сайта-донора для получения
													данных в&nbsp;необработанном виде и&nbsp;приведение их&nbsp;в&nbsp;удобный формат.{' '}
												</p>
												<p>
													Парсинг часто используется для наполнения своего сайта информацией из&nbsp;других источников
													или личного интернет-магазина товарами из&nbsp;маркетплейсов в&nbsp;автоматическом режиме,
													а&nbsp;также для удобства использования данных.
												</p>
											</>
										),
									},
									{
										title: 'Сколько стоит парсинг сайта?',
										description: (
											<>
												Цена парсинга текстов обычного сайта или товаров интернет-магазина в&nbsp;среднем составляет
												5000&nbsp;руб. Однако, если нужно спарсить большой объем разнородных данных из&nbsp;разных
												разделов и&nbsp;сайт-источник предпринимает меры по&nbsp;защите от&nbsp;парсинга, то&nbsp;цена
												будет существенно выше.
											</>
										),
									},
									{
										title: 'Парсите&nbsp;ли вы&nbsp;сайты с&nbsp;авторизацией?',
										description: (
											<>
												Да, только делаем это очень аккуратно. Это сложнее обычного парсинга, так как сайты-источники
												блокируют частые запросы к&nbsp;своим серверам, но, зачастую, зарегистрированным пользователям
												доступны api и&nbsp;есть возможность работы с&nbsp;использованием нескольких аккаунтов.
											</>
										),
									},
									{
										title: 'Как вы&nbsp;работаете с&nbsp;картинками и&nbsp;файлами?',
										description: (
											<>
												Мы&nbsp;храним URL-адреса на&nbsp;изображения и&nbsp;файлы. По&nbsp;этим адресам вы&nbsp;можете
												скачать файлы на&nbsp;свой сервер и&nbsp;обработать их&nbsp;как требуется.
											</>
										),
									},
									{
										title: 'Используете&nbsp;ли вы&nbsp;готовые программы для парсингов сайтов?',
										description: (
											<>
												Нет. Хоть сейчас и&nbsp;существует много различных инструментов для парсинга, все они являются
												в&nbsp;той или иной степени универсальными и&nbsp;решают общие задачи. Их&nbsp;тяжело
												адаптировать под нетривиальные требования. Часто бывает так, что в&nbsp;существующий парсинг
												требуется внести изменения, которые не&nbsp;поддерживает программа, и&nbsp;мы&nbsp;упираемся
												в&nbsp;ее&nbsp;функционал. Это может быть уникализация контента или обработка текста типографом.
												Это проблема всех &laquo;коробочных&raquo; решений, касающихся не&nbsp;только парсинга. Поэтому
												мы&nbsp;используем свои наработки, которые легко и&nbsp;точно настраиваются под конкретные
												задачи.
											</>
										),
									},
									{
										title: 'Можете&nbsp;ли вы&nbsp;спарсить email-адреса и&nbsp;телефоны?',
										description: (
											<>
												Обычно, эта информация относится к&nbsp;личном данным пользователей, поэтому, из&nbsp;правовых
												соображений, мы&nbsp;не&nbsp;парсим эти данные. Однако, если email-адреса или телефоны
												используются сайтом публично, к примеру, сайт является аггрегатором компаний и&nbsp;парсинг
												не&nbsp;нарушает условий использования, то&nbsp;парсим.
											</>
										),
									},
									{
										title: 'Сколько времени нужно для создания парсера?',
										description: (
											<>
												В&nbsp;среднем анализ сайта-донора, написание и&nbsp;отладка одного стандартного парсера
												занимает не&nbsp;более 3х&nbsp;дней. Если нужно написать парсер, который извлекает данные
												из&nbsp;нескольких источников или товары больших интернет-магазинов, маркетплейсов,
												то&nbsp;времени потребуется порядком больше.
											</>
										),
									},
									{
										title: 'В&nbsp;каком виде я&nbsp;получу данные и&nbsp;как их&nbsp;использовать?',
										description: (
											<div>
												<p>
													Мы&nbsp;выгружаем данные в&nbsp;файл Excel и&nbsp;предоставляем вам доступ. Популярные CMS
													поддерживают импорт данных с&nbsp;помощью стандартного функционала или расширений. Поэтому,
													в&nbsp;большинстве случаев, этот формат подойдет вам. Он&nbsp;также подходит и&nbsp;для
													источника данных в&nbsp;самописных бизнес-решениях.{' '}
												</p>
												<p>
													Однако, если вам нужно получить данные в&nbsp;другом виде, например в&nbsp;JSON или
													из&nbsp;базы данных, мы&nbsp;можем это сделать.
												</p>
											</div>
										),
									},
									{
										title: 'Можно&nbsp;ли изменить данные при парсинге?',
										description: (
											<>
												Да. Частая проблема с&nbsp;которые сталкиваются клиенты&nbsp;&mdash; уникальность текста после
												парсинга. Особенно когда дело касается парсинга десятков тысяч страниц товаров
												интернет-магазинов. Повторное использование текста может привести к&nbsp;санкциям
												со&nbsp;стороны поисковых систем. Мы&nbsp;занимается последующей обработкой текстов&nbsp;&mdash;
												повышением уникальности текста с&nbsp;разной степенью обработки и&nbsp;корректировкой формата
												текста с&nbsp;помощью типографа.
											</>
										),
									},
									{
										title: 'Законно&nbsp;ли парсить сайты?',
										description: (
											<>
												<p className="mb">
													Парсинг сайтов является законным, если он&nbsp;не&nbsp;нарушает запретов, установленных
													законодательством Российской Федерации. Поэтому мы&nbsp;соблюдаем следующие правила?{' '}
												</p>
												<ul className="list-none">
													<li>1. Собираем только открытые данные в&nbsp;свободном доступе. </li>
													<li>2. Данные не&nbsp;должны нарушать авторские и&nbsp;смежные права. </li>
													<li>3. Мы&nbsp;не&nbsp;собираем личные данные пользователей. </li>
													<li>4. Парсинг не&nbsp;должен нарушать условий использования сайта. </li>
													<li>5. Парсинг не&nbsp;должен негативно сказываться на&nbsp;работе сайта. </li>
												</ul>
											</>
										),
									},
									{
										title: 'Можно&nbsp;ли защититься от&nbsp;парсинга?',
										description: (
											<>
												Гарантированной 100% защиты от&nbsp;парсинга не&nbsp;существует. Есть разные методы защиты,
												но&nbsp;все они, как правило, обходятся определенными техническими способами. Если данные
												каким-либо образом доступны посетителю сайта, то&nbsp;они по&nbsp;определению смогут быть
												доступны и&nbsp;программе парсеру. Вопрос в&nbsp;том, насколько трудоемким будет процесс
												извлечения.
											</>
										),
									},
									{
										title: 'Парсите&nbsp;ли вы&nbsp;сайты, защищенные проверочным кодом?',
										description: (
											<>
												Да. Для этого у&nbsp;нас имеется готовый набор решений, которые позволяют как обойти
												верификацию, так и&nbsp;распознать код и&nbsp;использовать его для доступа к&nbsp;данным.
												Последний является более изощренным и&nbsp;дорогостоящим методом, но&nbsp;этот метод проверен
												и&nbsp;работает
											</>
										),
									},
									{
										title: 'Зачем нужен парсинг сайтов?',
										description: (
											<>
												Парсинг существенно автоматизирует ручную работу. Например, интернет-магазин может
												в&nbsp;автоматическом режиме синхронизировать десятки тысяч товаров с&nbsp;товарами поставщика,
												следить наличием товаров, обновлением цен, акций и&nbsp;прочее. Или скидочный аггрегатор,
												который парсит десятки разных источников и&nbsp;выводит самые выгодные акции на товары или
												услуги в&nbsp;одном удобном месте. Или директор компании может анализировать большой объем
												информации в&nbsp;едином, структурированном Excel-файле, не&nbsp;прибегая к&nbsp;рутинной работе
												по&nbsp;поиску ее&nbsp;в&nbsp;разных местах интернета и&nbsp;предлагать лучшие предложения своим
												клиентам.
											</>
										),
									},
									{
										title: 'Сколько времени парсятся сайты?',
										description: (
											<>
												Время, которое необходимо для парсинга легко рассчитать. Мы&nbsp;делаем запросы
												к&nbsp;сайтам-донорам с&nbsp;частотой 1-3&nbsp;секунды. Один запрос&nbsp;&mdash; это парсинг
												одной страницы. Ограничение необходимо для того, чтобы не&nbsp;сильно нагружать источник.
												В&nbsp;среднем к&nbsp;одному сайту мы&nbsp;посылаем порядка 40000 запросов в&nbsp;сутки. Если
												вам нужно спарсить каталог из&nbsp;10&nbsp;тысяч товаров, то&nbsp;мы&nbsp;спарсим его
												за&nbsp;5-6&nbsp;часов. Обычно этого хватает для того, чтобы поддерживать сайт в&nbsp;актуальном
												состоянии.
											</>
										),
									},
									{
										title: 'Зависит&nbsp;ли цена от&nbsp;количества страниц?',
										description: (
											<>
												Нет никакой разницы сколько страниц парсить. Парсинг будет выполнять свои задачи одинаково, как
												для одной страницы, так и&nbsp;для десятков тысяч страниц. Цена зависит от&nbsp;того, насколько
												разнородную информацию нужно извлечь. Например, если интернет-магазин имеет разные макеты
												категорий товаров, то, фактически, нужно писать несколько условно-отдельных парсеров под каждую
												категорию. Цена такого парсинга будет ненмого выше и&nbsp;она не&nbsp;зависит от&nbsp;количества
												страниц.
											</>
										),
									},
									{
										title: 'Есть&nbsp;ли какие-то ограничения на&nbsp;сайты-доноры?',
										description: (
											<>
												Ограничений к&nbsp;сайтам донорам нет. Мы&nbsp;можем отказать в&nbsp;предоставлении услуг лишь
												из&nbsp;правовых соображений. Например, в&nbsp;случае, если необходимо извлечь конфиденциальную
												информацию, личные данные пользователей или сбор информации будет нарушать авторские права.
											</>
										),
									},
									{
										title: 'Можно&nbsp;ли спарсить товары интернет-магазина определенной категории?',
										description: (
											<>
												Да. Вы&nbsp;можете выбрать определенные категории товаров, отдельные разделы сайтов, выборочные
												страницы и&nbsp;пр. Мы&nbsp;аккуратно распределим и&nbsp;положим эту информацию в&nbsp;один или
												несколько файлов, в&nbsp;удобном для вас виде.
											</>
										),
									},
									{
										title: 'Какой порядок оплаты?',
										description: (
											<>
												<p>
													Стоимость определяется услугами разработки и&nbsp;поддержки парсинга. Разработка
													осуществляется по&nbsp;предоплате. Вы&nbsp;вносите аванс в&nbsp;размере&nbsp;50%
													от&nbsp;стоимости заказа. Мы&nbsp;анализируем сайт, пишем парсер, тестируем
													и&nbsp;предоставляем вам результат. Вы&nbsp;оплачиваете оставшуюся часть после разработки
													парсера.{' '}
												</p>
												<p>
													Далее начинается услуга поддержки парсинга. Мы&nbsp;запускаем парсер на&nbsp;наших серверах
													и&nbsp;следим за&nbsp;работоспособностью. В&nbsp;случае изменения макета на&nbsp;сайте-доноре
													или срабатывании защиты, вносим корректировки в&nbsp;код программы. Оплата за&nbsp;техническую
													поддержку парсера в&nbsp;начале каждого месяца. Первый месяц&nbsp;&mdash; бесплатно.
												</p>
											</>
										),
									},
								]}
							/>
						</div>
						<div className="text-center">
							<FeedbackButton theme="primary" size="lg">
								Задать вопрос
							</FeedbackButton>
						</div>
					</Sector>
				</Triangle>
			</Triangle>

			{/*<ReviewsSlider />*/}
			<Contacts />
		</Layout>
	)
}

export default Home

import { useEffect, useState, useMemo } from 'react'

/**
 * Использование медиа-запросов
 * https://gist.github.com/gragland/ed8cac563f5df71d78f4a1fefa8c5633/c769cdc6a658b3925e9e2e204d228400d132965f
 * @param queries
 * @param values
 * @param defaultValue
 * @returns {*}
 *
 * const columnCount = useMedia(
		// Media queries
		['(min-width: 1200px)', '(min-width: 992px)', '(min-width: 768px)'],
		// Column counts (relates to above media queries by array index)
		[5, 4, 3],
		// Default column count
		2
	);
 *
 */
const useMedia = (queries, values, defaultValue) => {
	const mediaQueries = useMemo(() => (typeof window !== 'undefined' ? queries.map(query => matchMedia(query)) : []), [
		queries,
	])

	const match = () => {
		// Get first media query that matches
		const index = mediaQueries.findIndex(mq => mq.matches)
		// Return related value or defaultValue if none
		return values[index] || defaultValue
	}

	// State and setter for current value
	const [value, setValue] = useState(match)

	/* eslint-disable */
	useEffect(() => {
		const handleResize = () => {
			const valueNow = match()
			if (value !== valueNow) {
				setValue(valueNow)
			}
		}

		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize', handleResize)
	}, [value])
	/* eslint-enable */

	return value
}

export default useMedia
